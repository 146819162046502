import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { useIntl } from "gatsby-plugin-react-intl";
import NavbarTwo from "../components/App/NavbarTwo";
import Footer from "../components/App/Footer";
import Layout from "../components/App/Layout";
import DistributorBanner from "../components/Distributor/DistributorBanner";
import DistributorContent from "../components/Distributor/DistributorContent";

const About = () => {
  const {
    allContentfulMainSlider: { nodes: distributors },
  } = useStaticQuery(query);
  const intl = useIntl();
  const canonical = `${process.env.GATSBY_MAIN_URL}/international-distributors`;
  const distributorsImage =
    intl.locale === "en"
      ? distributors[0].distributorsImage.fluid
      : distributors[0].distributorsImageFr.fluid ||
        distributors[0].distributorsImage.fluid;
  const distributorsFixedImage =
    intl.locale === "en"
      ? distributors[0].distributorsImage.fixed.src
      : distributors[0].distributorsImageFr.fixed.src ||
        distributors[0].distributorsImage.fixed.src;

  return (
    <Layout
      title="International Distributors Page"
      canonical={canonical}
      image={distributorsFixedImage}
    >
      <NavbarTwo />
      <DistributorBanner distributorsImage={distributorsImage} />
      <DistributorContent />
      <Footer />
    </Layout>
  );
};

export const query = graphql`
  {
    allContentfulMainSlider {
      nodes {
        distributorsImage {
          fluid(quality: 100) {
            ...GatsbyContentfulFluid
          }
          fixed {
            src
          }
        }
        distributorsImageFr {
          fluid(quality: 100) {
            ...GatsbyContentfulFluid
          }
          fixed {
            src
          }
        }
      }
    }
  }
`;

export default About;
