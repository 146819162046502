import React from "react";
import ProductsList from "../MachineLearningAISolutions/ProductsList";
import LocationMap from "../Contact/LocationMap";

const DistributorContent = (props) => {
  return (
    <section className="blog-details-area mt-5 ">
      <div className="container">
        <div className="row product-upper-container">
          <div className="container-fluid">
            <div className=" ptb-100 product_meta">
              <h4 className="d-block">International Distributors</h4>
              <LocationMap />
              <ProductsList />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default DistributorContent;
