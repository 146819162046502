import React from "react";
import Image from "gatsby-image";


const AboutBanner = ({distributorsImage}) => {
  return (
    <div className="courses-details-area">
      <div className="courses-details-image">
        <Image fluid={distributorsImage} alt="Medart-distributors Banner" />
      </div>
    </div>
  );
};



export default AboutBanner;
